/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import colors from '../../../../utils/colors';
import { Box, Flex, BoxVisible, Container, BoxInline } from '../../../../style/basicStyle';
import * as ROLES from '../../../../constants/roles';
import feather from "../../../../utils/feather";
import { disableBrowserStyling } from "../../../../style/genericStyling";

const menuCategoryColor = css`
  --text-opacity: 0.5;
  opacity: 0.5;
  color: ${colors.primary};
`;

const displayHidden = css`
  display: none;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  left: -99999px;
`;

const displayBlock = css`
  display: block !important;
`;

const subMenuStyle = css`
  text-align: right !important;
  float: right;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
`;

const childLinkStyle = css`
  --text-opacity: 1;
  color: ${colors.primary};
`;

const featherParentLink = css`
  display: inline;
  transition: all 0.5s ease-in-out;
`;

const featherLink = css`
  transition: all 0.5s ease-in-out;
`;

const expandChild = css`
  position: absolute;
  overflow: visible;
  // padding-top: 4px;
  padding-left: 4px;
  margin-top: -2px;
  transition: all 0.5s ease-in-out;
`;

const hasRoles = (authUser) => {
  if (authUser === null || typeof authUser !== 'object') {
    return false;
  }

  const roles = authUser.roles;

  return roles !== null && typeof roles === 'object' && Object.keys(roles).length > 0;
}

const CategoryName = (item, index, menuToggle, adminVisible, resourcesPage, authUser) => {
  return (
  <>
    {item.categoryName === 'Admin' && (<Box
      css={[disableBrowserStyling]}
      my={[2]}
      fontSize={[4]}
      p={[1]}
      style={{ fontWeight: 'bold', cursor: 'pointer' }}
      onClick={() => menuToggle()}
      role="button"
      tabIndex="-1"
      onKeyPress={() => menuToggle()}
    >
      <Box fontSize={[resourcesPage ? 1 : 2]}>
        <BoxInline css={menuCategoryColor}>Admin</BoxInline>
        <BoxInline css={[expandChild]}>{(!adminVisible && feather('plus', ['25', '25'], featherLink, featherParentLink)) || feather('minus', ['25', '25'], featherLink, featherParentLink)}</BoxInline>
      </Box>
    </Box>) || (
      <Box fontSize={[resourcesPage ? 1 : hasRoles(authUser) ? 1 : 2]} css={[menuCategoryColor]}>
        {item.categoryName}
      </Box>
    )}
  </>
)};



// eslint-disable-next-line react/prop-types
const SubMenuMobile = ({ linkName, childLinks, hidden, authUser, toggleNav}) => {
  const resourcesPage = linkName === 'ResourcesDISABLED';
  const [adminVisible, setVisible] = useState(false);

  const menuToggle = () => {
    if (adminVisible) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };
  return (
    <Box css={[hidden && displayHidden, displayBlock, subMenuStyle]}>
      {childLinks.map(
        (item, index) =>
          // Display conditions
          ((!item.requireAuth && !authUser && !item.requireAdmin && !item.requireOrders && !item.requireTournaments && !item.requireClinics && !item.requireTryouts && !item.requireEvents && !item.requireTuition) || // Does our page not require authorization AND is there no user AND does it not require admin/orders/tournaments/events roles?
            (authUser && !item.hideWithAuth && !item.requireAdmin && !item.requireOrders && !item.requireTournaments && !item.requireClinics && !item.requireTryouts && !item.requireEvents && !item.requireTuition) || // Does our page require a user AND does our page need to be displayed if the user is logged in AND does it not require admin/orders/tournaments/events?
            (authUser && item.requireAuth && item.requireAdmin && authUser.roles[ROLES.ADMIN]) || // Does our page require a user AND does it need to be displayed with a logged in user AND is the user an admin?
            (authUser && item.requireAuth && item.requireTournaments && (authUser.roles[ROLES.TOURNAMENTS] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have tournament rights OR admin rights?
            (authUser && item.requireAuth && item.requireTryouts && (authUser.roles[ROLES.TRYOUTS] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have events rights OR admin rights?
            (authUser && item.requireAuth && item.requireEvents && (authUser.roles[ROLES.EVENTS] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have events rights OR admin rights?
            (authUser && item.requireAuth && item.requireTuition && (authUser.roles[ROLES.TUITION] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have tuition rights OR admin rights?
            (authUser && item.requireAuth && item.requireClinics && (authUser.roles[ROLES.CLINICS] || authUser.roles[ROLES.ADMIN])) || // Does our page require a user AND does it need to be displayed with a logged in user AND does user have clinic rights OR admin rights?
            (authUser && item.requireAuth && item.requireOrders && (authUser.roles[ROLES.ORDERS] || authUser.roles[ROLES.ADMIN]))) && // Does our page require a user AND does it need to be displayed with a logged in user AND does user have orders rights OR admin rights?
          !item.hidden && ( // can our user view orders?
          <>
            <Box css={[(item.categoryName !== 'Admin' && (((adminVisible && !item.staffLink) || (!adminVisible && item.staffLink)) && displayHidden)), item.hidden && displayHidden]} style={{ fontWeight: 'bold' }} key={`${index}SubMenu`}>
              {(item.categoryName && CategoryName(item, index, menuToggle, adminVisible, resourcesPage, authUser)) || (
                <Box onClick={toggleNav} onKeyPress={toggleNav} mt={[hasRoles(authUser) ? 0.25 : 1]}>
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  <Box fontSize={[resourcesPage ? 2 : hasRoles(authUser) ? 2 : 1]} key={item.linkName + index} my={[resourcesPage ? 0.75 : 2]}>
                    <Link to={`${item.link}`} css={[childLinkStyle]}>
                      {`${item.linkName}`}
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
          </>
          )
      )}
    </Box>
  );
};

export default SubMenuMobile;

SubMenuMobile.propTypes = {
  navDarkActive: PropTypes.bool,
  requireAdmin: PropTypes.bool,
  requireOrders: PropTypes.bool,
  requireEvents: PropTypes.bool,
  requireClinics: PropTypes.bool,
  requireTournaments: PropTypes.bool,
  requireAuth: PropTypes.bool,
  requireTuition: PropTypes.bool,
  hideWithAuth: PropTypes.bool,
  hidden: PropTypes.bool,
  linkName: PropTypes.string,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string,
      hidden: PropTypes.bool
    })
  ).isRequired
};

SubMenuMobile.defaultProps = {
  navDarkActive: false,
  requireAuth: false,
  hideWithAuth: false,
  requireAdmin: false,
  requireEvents: false,
  requireClinics: false,
  requireTournaments: false,
  requireOrders: false,
  hidden: false,
  requireTuition: false
};
